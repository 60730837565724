<template>
	<Dialog :dialog="addStockDialog" :dialog-width="dialogWidth">
		<template v-slot:title>Add New Stock</template>
		<template v-slot:body>
			<v-form
				ref="addStockForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit()"
			>
				<v-row class="px-4 mb-4">
					<template v-if="false">
						<v-col md="4" class="my-auto py-0">
							<label :for="`location-${uniqFieldId}`" class="btx-label mt-2">Location</label>
						</v-col>
						<v-col md="8" class="py-0">
							<AutoCompleteInput
								hide-details
								:items="locationList"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`location-${uniqFieldId}`"
								placeholder="Location"
								v-model="stock.location"
							></AutoCompleteInput>
						</v-col>
					</template>
					<v-col md="4" class="my-auto py-0">
						<label :for="`quantity-${uniqFieldId}`" class="btx-label mt-2 required">Quantity</label>
					</v-col>
					<v-col md="8" class="py-0">
						<QuantityInput
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							:id="`quantity-${uniqFieldId}`"
							:rules="[vrules.required(stock.quantity, 'Quantity')]"
							:class="{
								required: !stock.quantity,
							}"
							placeholder="Quantity"
							v-model="stock.quantity"
						></QuantityInput>
					</v-col>
					<template v-if="false">
						<v-col md="4" class="my-auto py-0">
							<label :for="`vendor-${uniqFieldId}`" class="btx-label mt-2">Supplier</label>
						</v-col>
						<v-col md="8" class="py-0">
							<AutoCompleteInput
								hide-details
								:items="vendorList"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`vendor-${uniqFieldId}`"
								placeholder="Supplier"
								v-model="stock.vendor"
							></AutoCompleteInput>
						</v-col>
					</template>
					<v-col md="4" class="my-auto py-0">
						<label :for="`comment-${uniqFieldId}`" class="btx-label mt-2">Comments</label>
					</v-col>
					<v-col md="8" class="py-0">
						<TextAreaInput
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							:id="`comment-${uniqFieldId}`"
							placeholder="Comments"
							v-model="stock.comment"
						></TextAreaInput>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				v-on:click="onSubmit()"
			>
				Save
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="addStockDialog = false"> Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import QuantityInput from "@/view/components/QuantityInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { SET_ERROR } from "@/core/services/store/common.module";
import { EventBus } from "@/core/event-bus/event.bus";
import { toSafeInteger } from "lodash";

export default {
	data() {
		return {
			formValid: true,
			addStockDialog: false,
			pageLoading: false,
			type: null,
			typeUuid: null,
			vendorList: [],
			locationList: [],
			stock: {
				location: 0,
				quantity: 1,
				vendor: 0,
				comment: null,
			},
		};
	},
	methods: {
		initStock(param) {
			this.stock = {
				location: 0,
				quantity: 1,
				vendor: 0,
				comment: null,
			};
			this.$nextTick(() => {
				this.addStockDialog = param;
			});
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.addStockForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.addStockForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.patch(`${this.type}/${this.typeUuid}/stock`, this.stock)
				.then(() => {
					//EventBus.$emit("reload:quantity-by-location", true);
					this.$emit("success", true);
					this.initStock(false);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	beforeDestroy() {
		EventBus.$off("add:stock");
	},
	mounted() {
		this.vendorList = this.localDB("suppliers", []);
		this.locationList = this.localDB("locations", []);

		EventBus.$on("add:stock", ({ type, typeUUID }) => {
			this.type = type;
			this.typeUuid = typeUUID;
			this.initStock(true);
		});
	},
	components: {
		Dialog,
		TextAreaInput,
		QuantityInput,
		AutoCompleteInput,
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 40);
		},
	},
};
</script>

<template>
	<div>
		<Dialog :dialog="retireDialog" :dialog-width="dialogWidth">
			<template v-slot:title
				>Retire {{ type == "Inventory" ? "Perishables" : type }} Quantity</template
			>
			<template v-slot:body>
				<v-form
					ref="retireForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="onSubmit"
				>
					<v-row class="retire-dialog">
						<v-col md="4" class="my-auto py-0">
							<label :for="`quantity-${uniqFieldId}`" class="btx-label mt-3 required">Quantity</label>
						</v-col>
						<v-col md="8" class="py-0">
							<QuantityInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`quantity-${uniqFieldId}`"
								:rules="[vrules.required(retire.quantity, 'Quantity')]"
								:class="{
									required: !retire.quantity,
								}"
								placeholder="Quantity"
								v-model="retire.quantity"
							></QuantityInput>
						</v-col>
						<v-col md="4" class="my-auto py-0">
							<label :for="`salvage-value-unit-${uniqFieldId}`" class="btx-label mt-3"
								>Salvage Value / Unit</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<QuantityInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`salvage-value-unit-${uniqFieldId}`"
								placeholder="Salvage Value / Unit"
								v-model="retire.salvage_unit"
							></QuantityInput>
						</v-col>
						<v-col md="4" class="my-auto py-0">
							<label :for="`total-salvage-unit-${uniqFieldId}`" class="btx-label mt-3 required"
								>Total salvage value</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<QuantityInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`total-salvage-unit-${uniqFieldId}`"
								:rules="[vrules.required(retire.total_salvage, 'Total salvage value')]"
								:class="{
									required: !retire.total_salvage,
								}"
								placeholder="Total salvage value"
								v-model="retire.total_salvage"
							></QuantityInput>
						</v-col>
						<template v-if="false">
							<v-col md="4" class="my-auto py-0">
								<label :for="`location-${uniqFieldId}`" class="btx-label mt-3 required">Location</label>
							</v-col>
							<v-col md="8" class="py-0">
								<AutoCompleteInput
									hide-details
									:items="locationList"
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`location-${uniqFieldId}`"
									:rules="[vrules.required(retire.location, 'Location')]"
									:class="{
										required: !retire.location,
									}"
									placeholder="Location"
									v-model="retire.location"
								></AutoCompleteInput>
							</v-col>
						</template>
						<v-col md="4" class="my-auto py-0">
							<label :for="`reason-${uniqFieldId}`" class="btx-label mt-3 required">Retire reason</label>
						</v-col>
						<v-col md="8" class="py-0">
							<AutoCompleteInput
								hide-details
								:items="reasonList"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`reason-${uniqFieldId}`"
								:rules="[vrules.required(retire.reason, 'Reason')]"
								:class="{
									required: !retire.reason,
								}"
								placeholder="Reason"
								v-model="retire.reason"
								v-on:click:append-outer="manageReasonDialog = true"
								append-outer-icon="mdi-cog"
							></AutoCompleteInput>
						</v-col>
						<v-col md="4" class="py-0">
							<label :for="`comment-${uniqFieldId}`" class="btx-label mt-3">Comments</label>
						</v-col>
						<v-col md="8" class="py-0">
							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`comment-${uniqFieldId}`"
								placeholder="Comments"
								v-model="retire.comment"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="false"
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onSubmit()"
				>
					Retire
				</v-btn>
				<v-btn
					v-if="!cartUuid"
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="addToCart()"
				>
					Add To Cart
				</v-btn>
				<v-btn
					v-if="cartUuid"
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="updateToCart()"
				>
					Update To Cart
				</v-btn>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
			</template>
		</Dialog>
		<template v-if="manageReasonDialog">
			<ManageRetireReason
				:dialog="manageReasonDialog"
				:reasons="reasonList"
				v-on:close-dialog="manageReasonDialog = false"
				v-on:success="getReasons"
			></ManageRetireReason>
		</template>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import QuantityInput from "@/view/components/QuantityInput";
import { toSafeInteger } from "lodash";
import { CART_COUNT } from "@/core/services/store/config.module";
import ManageRetireReason from "@/view/components/Manage-Retire-Reason.vue";

export default {
	name: "retire-template",
	title: "Retire Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		retireDialog: {
			type: Boolean,
			default: false,
		},
		cartUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			reasonList: [],
			manageReasonDialog: false,
			locationList: [],
			retire: {
				quantity: 0,
				salvage_unit: 0,
				total_salvage: 0,
				location: 0,
				reason: null,
				comment: null,
			},
		};
	},
	watch: {
		retireDialog: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.init();
				}
			},
		},
	},
	components: {
		Dialog,
		TextAreaInput,
		ManageRetireReason,
		QuantityInput,
		AutoCompleteInput,
	},
	methods: {
		init() {
			this.retire = {
				quantity: null,
				salvage_unit: null,
				total_salvage: null,
				location: null,
				reason: null,
				comment: null,
			};

			/* ApiService.get(`${this.endpoint}/${this.typeUuid}/location`)
				.then(({ data }) => {
					this.locationList = data;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				}); */
			if (this.cartUuid) {
				this.getCartRetired();
			}
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.retireForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.retireForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.patch(`${this.endpoint}/${this.typeUuid}/retire-item`, this.retire)
				.then(() => {
					this.$emit("success");
					this.$emit("close");
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		addToCart() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.retireForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.retireForm.validate()) {
				return false;
			}
			this.retire.type = this.endpoint == "asset-stock" ? 1 : 2;
			this.pageLoading = true;

			ApiService.post(`cart/${this.typeUuid}/add-retire-stock`, this.retire)
				.then(() => {
					this.$emit("success");
					this.$emit("close");
					this.$store.dispatch(CART_COUNT);
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateToCart() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.retireForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.retireForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.put(`cart/${this.typeUuid}/add-retire-stock/${this.cartUuid}`, this.retire)
				.then(() => {
					this.$emit("success");
					this.$emit("close");
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCartRetired() {
			this.pageLoading = true;
			ApiService.get(`cart/${this.cartUuid}`)
				.then(({ data }) => {
					this.retire = {
						quantity: data.quantity,
						salvage_unit: data.salvage_unit,
						total_salvage: data.total_salvage,
						location: null,
						reason: data.retire_reason,
						comment: data.description,
					};
					this.endpoint = data.asset && data.asset.id > 0 ? "asset" : "asset-stock";
					this.typeUuid = data.asset && data.asset.id > 0 ? data.asset.uuid : data.asset_stock.uuid;
					this.type = data.asset && data.asset.id > 0 ? "Asset" : "asset-stock";

					this.getRelatedDetails(this.type, this.typeUuid);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getReasons(data) {
			this.reasonList = data;
		},
	},
	mounted() {
		const today = new Date();
		this.retire.retire_date = this.formatDateRaw(today);

		this.reasonList = this.localDB("retire_reasons", []);
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
